<!-- System: STA
    Purpose: Team member create a card in create
            and update project modal for user
            selection and de-selection.
-->
<template>
  <v-container>
    <v-row class="white float-left row m-0 memberClass mt-2 mb-2">
      <v-col
        cols="12"
        :class="{
          green: member_data.is_assigned,
          grey: !member_data.is_assigned,
        }"
        class="float-left text-center p-2 pt-2"
      >
        <!-- Member Name-->
        <p :title="member_data.member.name" class="empNameLong text-capitalize">
          {{ employee_name }}
        </p>
      </v-col>
      <v-row class="w-100 float-left p-2 d-flex justify-center">
        <!-- Member Image-->
        <v-col
          cols="10"
          class="mt-3 d-flex justify-center"
          style="height: 150px"
        >
          <img
            v-if="this.displayTempImg"
            :src="require('@/assets/images/no-member.svg')"
            @error="
              $event.target.src = require('@/assets/images/no-member.svg')
            "
            alt="Thumbnail"
            class="member-image m-auto d-block"
            fluid
            thumbnail
          />
          <img
            v-else
            :src="url() + 'storage/' + member_data.member.image"
            @error="
              $event.target.src = require('@/assets/images/no-member.svg')
            "
            alt="Thumbnail"
            class="member-image m-auto d-block"
            fluid
            thumbnail
          />
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { baseURL } from "@/assets/js/urls";

export default {
  name: "TeamMember",
  props: ["member_data"],
  data() {
    return {
      displayTempImg: true,
      selected: null,
      invited_project_id: null,
    };
  },
  computed: {
    /**
     * This Function returns the employee
     * name in reduced form
     */
    employee_name() {
      return this.member_data.member.name
        ? this.member_data.member.name.length > 13
          ? this.member_data.member.name.match(
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g
            ) !== null
            ? this.member_data.member.name
            : this.member_data.member.name
                .match(/\b(\w)/g)
                .join(" ")
                .toUpperCase()
                .slice(0, -1)
                .concat(" " + this.member_data.member.name.split(" ").slice(-1))
          : this.member_data.member.name
        : "(No name set yet)";
    },
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
  },
  methods: {
    /**
     * This Function returns the base url
     */
    url() {
      return baseURL.API_URL;
    },
  },
  mounted() {
    setTimeout(() => {
      this.displayTempImg = false;
    }, 5000);
  },
};
</script>


<style scoped>
.member-image {
  height: 100px;
  width: 110px;
}

.empNameLong {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-size: 14px;
}

.memberClass {
  height: 155px;
  width: 143px;
  box-shadow: inset 0 1px 3px 0 rgba(147, 147, 147, 0.17),
    0 0 10px 0 rgba(214, 214, 214, 0.47);
  border-radius: 3px;
}

@media (max-width: 430px) {
  .memberClass {
    width: 130px !important;
  }
}

@media (max-width: 320px) {
  .memberClass {
    width: 143px !important;
  }
}

.img-thumbnail {
  padding: unset !important;
  background-color: unset !important;
  border: unset !important;
  border-radius: 50%;
  width: 86px;
  height: 84px;
}
</style>